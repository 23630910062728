<template>
    <div style="width: 1200px;margin: auto;padding: 20px 0px;">
      <h1 style="text-align: center;padding: 30px;">{{ obj.name }}</h1>
      <div  v-html="obj.content"></div>
    </div>
</template>
  
<script>
import {getProtocol} from '@/api/main.js'
export default{
  data(){
      return {
        obj:{}
      }
  },
  mounted() {
    this.getProtocol()
  },
  methods:{
    getProtocol(){
      getProtocol({
        id:5//1公司介绍、2品牌介绍、3购物说明、4隐私权益及网站使用条款、5会员制度
      }).then(res=>{
        if(res.code==0){
          if (this.isChinese()) {
            this.obj = res.data
          }else{
            this.obj = {
              ...res.data,
              name:res.data.nameEng,
              content:res.data.contentEng
            }
          }
        }else{
          this.showMessage();
        }
      })
    }
  }
}
</script>
  <style lang="scss">
  
  </style>